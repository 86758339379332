// import React, { useEffect, useState } from "react";
// import "./Membership.css";
// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";
// // Import Swiper styles
// import "swiper/css";
// import { Navigation } from "swiper/modules";
// import Membership_card from "../Common-Component/Membership_card/Membership_card";
// import { AllPlans } from "../../utils/apis/master/Master";

// const membershipData = [
//   {
//     headingText: "Silver Membership",
//     btnText: "View More",
//     amountText: "30000",
//   },
//   { headingText: "Gold Membership", btnText: "View More", amountText: "60000" },
//   {
//     headingText: "Platinum Membership",
//     btnText: "View More",
//     amountText: "100000",
//   },
//   {
//     headingText: "Silver Membership",
//     btnText: "View More",
//     amountText: "30000",
//   },
//   { headingText: "Gold Membership", btnText: "View More", amountText: "60000" },
//   {
//     headingText: "Platinum Membership",
//     btnText: "View More",
//     amountText: "100000",
//   },
// ];

// const Membership = () => {
//   const [plans, setPlans] = useState([]);
//   const getPlans = async () => {
//     const res = await AllPlans();
//     if (res?.success) {
//       setPlans(res.data);
//     }
//   };

//   useEffect(() => {
//     getPlans();
//   }, []);

//   useEffect(() => {
//     window.scrollTo(0, 0); // Scroll to the top when component mounts
//   }, []);
//   return (
//     <>
//       <section className="Membership TermsCondition">
//         <div className="container">
//           <div className="relation-div">
//             <div className="row">
//               <div className="col-md-12 mx-auto">
//                 <div className="Membership-banner-img">
//                   <img
//                     src={
//                       process.env.PUBLIC_URL +
//                       "/assets/Image/membership/membership-banner.png"
//                     }
//                     className="Membership-banner"
//                     alt="Membership Banner"
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="absolute-content">
//               <div className="heading-holder">
//                 <h1>Membership</h1>
//               </div>
//               <div className="row me-0 ms-0 justify-content-center">
//                 <div className="col-xl-10 col-md-11 col-sm-11">
//                   <div className="Membership-card-holder">
//                     <div className="row justify-content-center">
//                       <Swiper
//                         navigation={true}
//                         modules={[Navigation]}
//                         spaceBetween={20}
//                         slidesPerView={3}
//                         className="Membership_card_swiper"
//                         breakpoints={{
//                           280: {
//                             slidesPerView: 1,
//                           },

//                           640: {
//                             slidesPerView: 1,
//                           },
//                           768: {
//                             slidesPerView: 2,
//                           },
//                           1024: {
//                             slidesPerView: 2,
//                           },
//                           1200: {
//                             slidesPerView: 3,
//                           },
//                           1400: {
//                             slidesPerView: 3,
//                           },
//                         }}
//                       >
//                         {plans.map((membership, index) => (
//                           <SwiperSlide key={index}>
//                             <div className="card-holder">
//                               <Membership_card
//                                 headingText={membership.name}
//                                 description={membership.description}
//                                 btnText={membership.btnText}
//                                 amountText={membership.amount}
//                               />
//                             </div>
//                           </SwiperSlide>
//                         ))}
//                       </Swiper>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Membership;

import React, { useEffect, useState } from "react";
import "./Membership.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import { Navigation } from "swiper/modules";
import Membership_card from "../Common-Component/Membership_card/Membership_card";
import { AllPlansDetails } from "../../utils/apis/master/Master";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const membershipData = [
  {
    headingText: "Silver Membership",
    btnText: "View More",
    amountText: "30000",
  },
  { headingText: "Gold Membership", btnText: "View More", amountText: "60000" },
  {
    headingText: "Platinum Membership",
    btnText: "View More",
    amountText: "100000",
  },
  {
    headingText: "Silver Membership",
    btnText: "View More",
    amountText: "30000",
  },
  { headingText: "Gold Membership", btnText: "View More", amountText: "60000" },
  {
    headingText: "Platinum Membership",
    btnText: "View More",
    amountText: "100000",
  },
];

const Membership = () => {
  const [plans, setPlans] = useState([]);
  const getPlans = async () => {
    const res = await AllPlansDetails();
    if (res?.success) {
      setPlans(res.data);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const renderTooltip = (headingText) => {
    return (
      <Tooltip
        id="button-tooltip"
        className="custom-tooltip"
        style={{ background: "yellow", color: "black" }}
      >
        <div
          className="inner_tooltip"
          style={{
            position: "absolute",
            backgroundColor: "#fece00",
            color: "black",
            border: "1px solid yellow",
            width: "200px",
            fontWeight: "600",
            padding: "10px",
            top: "-60px",
            left: "-92px",
          }}
        >
          {headingText == "Silver"
            ? "Spend More Than 3000 To Get Silver Membership"
            : headingText == "Gold"
            ? "Spend More Than 6000 To get Gold Membership"
            : "Spend More Than 10000 To Get Platinum Membership"}
        </div>
      </Tooltip>
    );
  };

  return (
    <>
      <section className="Membership TermsCondition">
        <div className="container">
          <div className="relation-div">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="Membership-banner-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Image/membership/membership-banner.png"
                    }
                    className="Membership-banner"
                    alt="Membership Banner"
                  />
                </div>
              </div>
            </div>
            <div className="absolute-content">
              <div className="heading-holder">
                <h1>Membership</h1>
              </div>
              <div className="row me-0 ms-0 justify-content-center">
                <div className="col-xl-10 col-md-11 col-sm-11">
                  <div className="Membership-card-holder">
                    <div className="row justify-content-center">
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={3}
                        className="Membership_card_swiper"
                        breakpoints={{
                          280: {
                            slidesPerView: 1,
                          },

                          640: {
                            slidesPerView: 1,
                          },
                          768: {
                            slidesPerView: 2,
                          },
                          1024: {
                            slidesPerView: 2,
                          },
                          1200: {
                            slidesPerView: 3,
                          },
                          1400: {
                            slidesPerView: 3,
                          },
                        }}
                      >
                        {plans.map((membership, index) => (
                          <SwiperSlide key={index}>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(membership.name)}
                            >
                              <div className="card-holder">
                                <Membership_card
                                  headingText={membership.name}
                                  btnText={"View More"}
                                  amountText={membership.amountText}
                                  details={membership}
                                />
                              </div>
                            </OverlayTrigger>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Membership;
