import React, { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import "./Footer.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import classNames from "classnames";
import { Context } from "../../utils/context";
import { AppSetup } from "../../utils/apis/common/Common";
import Successfull_Modal from "../Common_modal/Successfull_Modal/Successfull_Modal";
import Newly_launch from "../Common_modal/newly_launch/Newly_launch";
import Map_Modal from "../Common_modal/Map_Modal/Map_Modal";
import Map_Modal_two from "../Common_modal/Map_Modal_two/Map_Modal_two";
import Scanner_Modal from "../Common-Component/Scanner_Modal/Scanner_Modal";
import parse from "html-react-parser";
import Cookies from "js-cookie";

const Footer = () => {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [modalScannerShow, setModalScannerShow] = React.useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalMapShow, setModalMapShow] = React.useState(false);
  const [modalMapTwoShow, setModalMapTwoShow] = React.useState(false);
  const [successful, setSuccessful] = useState(false);
  const { IMG_URL, postData } = useContext(Context);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [emailerror, setemailErrors] = useState("");

  const onSubmit = async (d) => {
    const res = await postData(`/without-login/info-subscribe`, d);
    if (res?.success) {
      reset();
      setSuccessful(true);
      setTimeout(() => {
        setSuccessful(false);
        window.scrollTo(0, 0);
      }, 3000);
    } else {
      setemailErrors(res?.message);
    }
  };

  const [setups, setSetup] = useState();
  const getSetup = async () => {
    const res = await AppSetup();
    if (res?.success) {
      setSetup(res?.data);
    }
  };

  useEffect(() => {
    getSetup();
  }, []);

  return (
    <>
      <section className="footer">
        <Container>
          {/* subscribe-banner-start */}
          <div className="footer-banner">
            <div className="footer-bannermain">
              <div className="footer-imgdiv">
                <img
                  className="footerimg"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Image/footer/individual.png"
                  }
                  alt="Logo"
                />
              </div>

              <div className="ban-contentmain">
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-9 col-sm-12 ">
                    <div className="banconts">
                      <h4 className="newssetl">
                        Subscribe to our newsletter to get updates on our latest
                        collections for you
                      </h4>
                      <p className="getss">
                        Get Special deals with our subscription
                      </p>
                      <div className="email-box">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <InputGroup className="mb-1">
                            <Form.Control
                              type="email"
                              placeholder="Email"
                              {...register("email", {
                                required: "Email Required",
                                pattern: {
                                  value:
                                    /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                  message: " Invalid Email",
                                },
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.email,
                              })}
                            />

                            <InputGroup.Text id="basic-addon2">
                              <button type="submit" className="subscribe-btn">
                                Subscribe
                              </button>
                              {/* <Common_Button
                                text="  Subscribe"
                                className='subscribe-btn'
                                type="submit"
                              /> */}
                            </InputGroup.Text>
                          </InputGroup>

                          {errors.email && (
                            <div className="text-danger text-end mb-0 pe-3">
                              <span>{errors.email.message}</span>
                            </div>
                          )}
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* subscribe-banner-end */}
          <div className="row">
            <div className="col-lg-3 col-md-4  ">
              <Link as={Link} to="/">
                {/* <img
                  className="headlogo"
                  src={IMG_URL + setups?.image}
                  alt="Logo"
                /> */}
                <span className="boomcor"> Boomerbucks Corporation</span>
              </Link>

              <p className="hideingp">
                We are hiding here{" "}
                <FontAwesomeIcon
                  className="location-icon me-1"
                  // icon={faLocationDot}
                />
              </p>
              <div className="text-area-loaction">
                <p className="adddppp">
                  <FontAwesomeIcon icon="fa-solid fa-location-dot" />{" "}
                  <strong> REGIONAL OFFICE</strong>
                </p>
                <p
                  className="adddppp ms-2"
                  onClick={() => setModalMapShow(true)}
                >
                  {htmlToReactParser.parse(setups?.address)}
                </p>

                <br></br>

                <p className="adddppp">
                  <FontAwesomeIcon icon="fa-solid fa-location-dot" />{" "}
                  <strong>Corporate office</strong>
                </p>
                <p
                  className="adddppp ms-2"
                  onClick={() => setModalMapTwoShow(true)}
                >
                  {htmlToReactParser.parse(setups?.address_two)}
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-8  ">
              <div className="row location-holder">
                <div className="col-sm-6">
                  <h3 className="footerhead">Quicks Links</h3>
                  <div className="call-holder me-4">
                    <Link to={"/"}>
                      <span className="me-2">Home</span>
                    </Link>
                  </div>
                  <div className="call-holder me-4">
                    <Link to={"/near-me"}>
                      <span className="me-2">Near me</span>
                    </Link>
                  </div>
                  <div className="call-holder me-4">
                    <Link to={"/about-us"}>
                      <span className="me-2">About us</span>
                    </Link>
                  </div>
                  <div className="call-holder me-4">
                    <Link to={"/contact-us"}>
                      <span className="me-2">Contact us</span>
                    </Link>
                  </div>
                  <div className="call-holder me-4">
                    <Link to={"/membership"}>
                      <span className="me-2">Membership</span>
                    </Link>
                  </div>
                  <Link to={"/my-coupons"}>
                    <div className="call-holder me-4">
                      <span className="me-2">Coupon </span>
                    </div>
                  </Link>
                  <div className="call-holder me-4">
                    <Link to={"/faq"}>
                      <span className="me-2">Faq</span>
                    </Link>
                  </div>
                  <div className="call-holder me-4">
                    <Link to={"/"} onClick={() => setModalShow(true)}>
                      <span
                        className="me-2"
                        // onClick={() => toggleShow(!show)}
                      >
                        Newly Launch
                      </span>
                    </Link>
                  </div>

                  {/* {show &&
                    <>
                      <div className="launchhvdodiv">
                        <iframe width="1000px" height="800px" src={process.env.PUBLIC_URL + '/assets/video/launch-product/first.mp4'} title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                      </div>
                    </>
                  } */}
                </div>
                <div className="col-sm-6">
                  <h3 className="footerhead">Earn with us</h3>
                  <div className="call-holder me-4">
                    <Link to={"/become-seller"}>
                      <span
                        className="me-2"
                        // onClick={Cookies.remove("user_id")}
                      >
                        Become a seller
                      </span>
                    </Link>
                  </div>
                  <div className="call-holder me-4">
                    <Link to={"/sell-on-boomerbucks"}>
                      <span className="me-2">Sell on Boomerbucks</span>
                    </Link>
                  </div>
                  <div className="call-holder me-4">
                    <Link to={"/promote-product"}>
                      <span className="me-2">Become Promoter </span>
                    </Link>
                  </div>
                  <div className="call-holder me-4">
                    <Link to={"/become-franchise"}>
                      <span className="me-2">Become Franchise</span>
                    </Link>
                  </div>

                  <div className="call-holder me-4">
                    <Link to={"/become-delivery"}>
                      <span className="me-2">Become Delivery Partner</span>
                    </Link>
                  </div>

                  <div className="call-holder me-4">
                    <Link to={"/become-distributor"}>
                      <span className="me-2">Become Distributor</span>
                    </Link>
                  </div>

                  <div className="call-holder me-4">
                    <Link to={"/career"}>
                      <span className="me-2">Boomerbucks Career</span>
                    </Link>
                  </div>

                  <div className="call-holder me-4">
                    <Link to={"/contact-us"}>
                      <span className="me-2">Inquiry Form</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-5  helpdeskposs">
              <div className="location-holder  ">
                <div className="address-holder">
                  <div className="text-holder mt-4">
                    <Link to={"/contact-us"}>
                      <h3 className="footerhead link_text">Customer Help Desk Number</h3>
                    </Link>
                  </div>

                  <div className="call-holder ">
                    <span>+91 {setups?.complain_assistance}</span>
                  </div>

                  <div className="text-holder mt-4">
                    <h3 className="footerhead">Seller Help Desk Number</h3>
                  </div>

                  <div className="call-holder ">
                    <a
                      target="_blank"
                      class="webwhats"
                      href="https://web.whatsapp.com/send?phone=9795598526"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Icon/whatsapp-icon.png"
                        }
                        className="whatssiccc me-2"
                      />
                      <span>+91 {setups?.seller_help_desk_no}</span>
                    </a>

                    <a
                      target="_blank"
                      class="mobwhats"
                      href="https://wa.me/9795598526"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Icon/whatsapp-icon.png"
                        }
                        className="whatssiccc me-2"
                      />
                      <span>+91 {setups?.seller_help_desk_no}</span>
                    </a>
                  </div>

                  {/* <div className="text-holder">
                    <h3 className="footerhead">Return Exchange Number</h3>
                  </div>
                  <div className="call-holder me-4">
                    <FontAwesomeIcon
                      icon="fa-solid fa-phone"
                      className="call-icon me-2"
                    />
                    <span className="me-2">{setups?.return_exchange} </span>
                  </div> */}

                  <div className="call-holder me-4">
                    <FontAwesomeIcon
                      icon="fa-solid fa-envelope"
                      className="call-icon me-2"
                    />
                    <span className="me-2">{setups?.email}</span>
                  </div>

                  <div className="text-holder">
                    <h3 className="footerhead">UPI payment</h3>
                  </div>

                  <div
                    className="scanner-img-holder "
                    onClick={() => setModalScannerShow(true)}
                  >
                    <img
                      className="scanner-img"
                      src={IMG_URL + setups?.scanner}
                    />
                  </div>

                  {/* <div className="call-holder me-4">
                    <FontAwesomeIcon
                      icon="fa-solid fa-phone"
                      className="call-icon me-2"
                    />
                    <span className="me-2">+91 780 094 2177</span>
                  </div> */}

                  <div className="jfd-bot mt-4">
                    <div className="call-holder ">
                      <FontAwesomeIcon
                        icon="fa-brands fa-whatsapp"
                        className="call-icon me-2"
                      />
                      <span>Download Users (500+) </span>
                    </div>
                    <div className="socialicccc d-flex ">
                      <a
                        href="https://apps.apple.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="icccc"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/footer/appstore.jpg"
                          }
                          alt="App Store"
                        />
                      </a>
                      <img
                        className="icccc"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/footer/googleplay.jpg"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row copyright-holder">
              <div className="col-lg-4 pt-2">
                <div className="soccicooo-flex my-auto">
                  <div className="text-holder my-auto">
                    <h3 className="footerhead my-auto">Follow Us On :</h3>
                  </div>

                  <div className="ico-div  my-auto">
                    <Link
                      to={
                        "https://www.facebook.com/boomerbucks.in?mibextid=ZbWKwL"
                      }
                      target="_blank"
                    >
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/footer/facebook.png"
                          }
                          className="socialIcon"
                        />
                      </div>
                    </Link>

                    <Link to={"t.me/boomerbucksindia"} target="_blank">
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/footer/telegram.png"
                          }
                          className="socialIcon"
                        />
                      </div>
                    </Link>

                    <Link to={"https://x.com/boomerbucks_ind"} target="_blank">
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/footer/xmark.png"
                          }
                          className="socialIcon"
                        />
                      </div>
                    </Link>

                    <Link
                      to={"https://www.youtube.com/@Boomerbucks_Corp"}
                      target="_blank"
                    >
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/footer/youtube.png"
                          }
                          className="socialIcon"
                        />
                      </div>
                    </Link>

                    <Link
                      to={
                        "https://www.instagram.com/boomerbucks.in?utm_source=qr&igsh=MTMyOTd5MGx5M201Nw=="
                      }
                      target="_blank"
                    >
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/footer/insta.png"
                          }
                          className="socialIcon"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 text-lg-center order-md-1 order-2">
                <p>
                  Copyright ©2024 All Rights Reserved.{" "}
                  {/* <Link to="https://profcymaglobal.com/" target="___blank"> */}
                  Developed by Profcyma.
                  {/* </Link> */}
                </p>
              </div>

              <div className="col-lg-4  order-md-2 order-1">
                <div className="footerbottom">
                  <div className=""></div>

                  {/* <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/footer/facebook.png"
                      }
                      className="socialIcon"
                    />
                  </div>

                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/Image/footer/link.png"
                      }
                      className="socialIcon"
                    />
                  </div>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/footer/xmark.png"
                      }
                      className="socialIcon"
                    />
                  </div>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/footer/youtube.png"
                      }
                      className="socialIcon"
                    />
                  </div>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/footer/insta.png"
                      }
                      className="socialIcon"
                    />
                  </div> */}

                  <div className="me-4">
                    <Link to={"/terms-conditions"}>
                      <p>Terms & Conditions</p>
                    </Link>
                  </div>

                  <div className="">
                    <Link to={"/privacy-policy"}>
                      <p>Privacy Policy</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Newly_launch
          show={modalShow}
          onHide={() => setModalShow(false)}
          setModalShow={setModalShow}
          modalShow={modalShow}
        />
      </section>
      <Successfull_Modal
        message={`Successfully Subscribed`}
        show={successful}
        onHide={() => setModalShow(false)}
      />

      <Map_Modal
        show={modalMapShow}
        address={setups?.address}
        onHide={() => setModalMapShow(false)}
      />
      <Map_Modal_two
        show={modalMapTwoShow}
        address={setups?.address_two}
        onHide={() => setModalMapTwoShow(false)}
      />

      <Scanner_Modal
        show={modalScannerShow}
        data={setups?.scanner}
        onHide={() => setModalScannerShow(false)}
      />
    </>
  );
};

export default Footer;
