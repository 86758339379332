import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Category = async () => {
  try {
    return await getData(`/without-login/allcategories`);
  } catch (error) {
    console.log(error);
  }
};

export const GetAllBrands = async () => {
  try {
    return await getData(`/without-login/allbrands`);
  } catch (error) {
    console.log(error);
  }
};

export const CategoryForSeller = async () => {
  try {
    return await getData(`/without-login/all-category-for-seller`);
  } catch (error) {
    console.log(error);
  }
};

export const SubCategory = async (id) => {
  try {
    return await getData(`/without-login/allsubcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const singleSubCategory = async (id) => {
  try {
    return await getData(`/without-login/subcategory/${id}`);
  } catch (error) {
    console.log(error);
  }
};
export const ChildSubCategory = async (id) => {
  try {
    return await getData(`/without-login/allchildsubcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const popularCategory = async () => {
  try {
    return await getData(`/without-login/all-pupular-category`);
  } catch (error) {
    console.log(error);
  }
};

export const Products = async (
  category_id = "",
  sub_category_id = "",
  brand_id = "",
  sort_price = "",
  sort_priceAsc = "",
  price_range = ""
) => {
  try {
    let url = "/without-login/product/filter";

    // if (
    //   category_id &&
    //   sub_category_id &&
    //   brand_id &&
    //   sort_price &&
    //   sort_priceAsc &&
    //   price_range
    // ) {
    url += `?category_id=${category_id}&sub_category_id=${sub_category_id}&brand_id=${brand_id}&sort_price=${sort_price}&sort_priceasc=${sort_priceAsc}&end_price=${price_range}`;
    // }
    // if (category_id) {
    //   url += `?category_id=${category_id}`;
    // }
    // if (sub_category_id) {
    //   url += `?sub_category_id=${sub_category_id}`;
    // }
    // if (brand_id) {
    //   url += `?brand_id=${brand_id}`;
    // }
    // if (sort_price) {
    //   url += `?sort_price=${sort_price}`;
    // }
    // if (sort_priceAsc) {
    //   url += `?sort_priceasc=${sort_priceAsc}`;
    // }
    // if (price_range) {
    //   url += `?end_price=${price_range}`;
    // }

    // if (seller_id) {
    //   url += `&seller_id=${seller_id}`;
    // }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const Productss = async (seller_id = "") => {
  try {
    let url = "/without-login/product/filter";

    if (seller_id) {
      url += `&seller_id=${seller_id}`;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const getProductDetail = async (id) => {
  try {
    return await getData(`/without-login/product/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getProductVariant = async (id) => {
  try {
    return await getData(`/without-login/product/variants/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getProductSubVariant = async (id) => {
  try {
    return await getData(`/without-login/product/sub-variants/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getVariant = async (id) => {
  try {
    return await getData(`/without-login/product/variant/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getMultiVariant = async (id) => {
  try {
    return await getData(`/without-login/product/multi-variant/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const allProductReviewGet = async (id) => {
  try {
    return await getData(`/without-login/home/show/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const productReviewGet = async (id) => {
  try {
    return await getData(`/review/show/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const productReviewPost = async (data) => {
  try {
    return await postData(`/review/add`, data);
  } catch (error) {
    console.log(error);
  }
};

export const productList = async () => {
  try {
    return await getData(`/without-login/product`);
  } catch (error) {
    console.log(error);
  }
};
