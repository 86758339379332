import React, { useContext, useEffect, useState } from "react";
import "./wallettranctionprofile.css";
import deleteimg from "./image/delete-sweep.png";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Form,
  Modal,
  Table,
  InputGroup,
} from "react-bootstrap";
import classNames from "classnames";
import Delete_Address from "../Common_modal/Delete_Address/Delete_Address";
import Delete_Transactions from "../Common_modal/Delete_Transactions/Delete_Transactions";
import { walletHistory } from "../../utils/apis/myAccount/myAccount";
import { Context } from "../../utils/context";
import { useForm } from "react-hook-form";
import Successfull_Modal from "../Common_modal/Successfull_Modal/Successfull_Modal";
import CustomPagination from "../Common_modal/CustomPagination";
import { getData } from "../../utils/api";

const WalletTranctionProfile = () => {
  const { postData, signin, userdata } = useContext(Context);

  console.log(userdata, "userdatauserdatauserdatauserdata");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [wallet, setWallet] = useState({ totalPages: 1, currentPage: 1 });

  const getOrders = async () => {
    const res = await getData(`/admin/user-wallet-history?page=${currentPage}`);
    if (res?.success) {
      setWallet(res?.data);
    }
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
  } = useForm();

  const [successModal, setSuccessModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const onSubmit = async (data) => {
    const res = await postData(`/wallet-request/add`, data);
    if (res?.success) {
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
        handleClose(true);
        reset();
        getOrders();
      }, 3000);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getOrders();
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [modalShow, setModalShow] = React.useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <section className="wallet desk-top-view-wallet">
        <div className="container">
          <div className="row">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mt-3">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/wallet">Wallet</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {" "}
                  Wallet Transactions
                </li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="d-flex justify-content-end mt-4 mb-5">
              <div className="amount-details pt-2">
                <p>Available Wallet Amount : ₹ {userdata?.wallet_amount}</p>
              </div>
              <div className="button-recharge ms-3">
                <Link to="/wallet">
                  {" "}
                  <button className="btn btn-recharge">Recharge</button>
                </Link>
              </div>

              {/* {signin ? (
                <div className="button-recharge ms-3">
                  <button className="btn btn-recharge" onClick={handleShow}>
                    Withdraw Request
                  </button>
                </div>
              ) : (
                <Link to="/login">
                  <div className="button-recharge ms-3">
                    <button className="btn btn-recharge" onClick={handleShow}>
                      Withdraw Request
                    </button>
                  </div>
                </Link>
              )} */}

              <div className="button-recharge ms-3">
                <button className="btn btn-recharge" onClick={handleShow}>
                  Withdraw Request
                </button>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <Table responsive bordered>
              <div className="table-responsive">
                <table className="table   wallet-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Amount Type</th>
                      <th>Transaction Amount</th>
                      <th>Transaction Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wallet?.data?.map((value, index) => (
                      <tr>
                        <td>
                          {new Date(value.createdAt).toLocaleDateString()}
                        </td>
                        <td>
                          {new Date(value.createdAt).toLocaleTimeString()}
                        </td>
                        <td>{value.type}</td>
                        <td>{value?.amount}</td>
                        <td>
                          {value?.request_status ? value?.request_status : "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Table>
            <div className="paginationss">
              <CustomPagination
                currentPage={currentPage}
                totalPages={wallet?.totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </section>

      <Delete_Transactions
        message={"Are you sure you want to permanently delete this history?"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <>
        <div className="withrow-request Reason_Modal">
          <Modal
            className="withrow_request Cancel_Reason_Modal"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>Withdrawal Request</Modal.Header>
            <Form role="form">
              <Modal.Body>
                <div className="col-md-6">
                  <div className="main-form-section mt-3">
                    <Form.Group>
                      <Form.Label>Amount</Form.Label>

                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="amount"
                          placeholder="Amount"
                          className={classNames("", {
                            "is-invalid": errors?.amount,
                          })}
                          {...register("amount", {
                            required: "Amount is required",
                            validate: (value) => {
                              // Check if the value is a number and an integer
                              if (!Number.isInteger(Number(value))) {
                                return "Amount must be an integer.";
                              }

                              // Check if the value is non-negative
                              if (value < 0) {
                                return "Amount cannot be negative.";
                              }

                              // Check if the amount is less than or equal to wallet amount
                              if (value > userdata?.wallet_amount) {
                                return `Insufficient Wallet Balance`;
                              }

                              // If all conditions are satisfied, return true
                              return true;
                            },
                          })}
                        />
                      </InputGroup>
                      {errors.amount && (
                        <span className="text-danger">
                          {errors.amount.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button className="close-btn" onClick={handleClose}>
                  Close
                </Button>
                <Button className="save-btn" onClick={handleSubmit(onSubmit)}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </>
      <Successfull_Modal
        show={successModal}
        onHide={() => setSuccessModal(false)}
        message={"Wallet Request Sent Successfully"}
      />
    </>
  );
};

export default WalletTranctionProfile;
