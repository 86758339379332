import { React, useState, useEffect, useContext } from "react";
import "./SecondVendor.css";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import Veggies_Section_Card from "../../Common-Component/Veggies_Section_Card/Veggies_Section_Card";
import Product_Card from "../../Common-Component/Product_Card/Product_Card";
import { Context } from "../../../utils/context";

import { Products } from "../../../utils/apis/Product/Product";
import { useOutlet } from "react-router-dom";

import SupermarketModal from "../../Common_modal/Supermarket-modal/SupermarketModal";

const SecondVendor = ({ heading, product }) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeHearts, setActiveHearts] = useState([]);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const { IMG_URL } = useContext(Context);

  const [addCartModal, setAddCartModal] = useState(false);
  const [products, setProduct] = useState([]);
  const getProducts = async () => {
    const res = await Products(product?.category_id, "", "");
    if (res?.success) {
      setProduct(res?.data?.data);
    }
  };

  useEffect(() => {
    getProducts();
  }, [product]);

  return (
    <>
      <section className="Frequently-Bought">
        <Container>
          <h1 className="title">{heading}</h1>
          <Swiper
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              486: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1400: {
                slidesPerView: 5,
                spaceBetween: 5,
              },
              1900: {
                slidesPerView: 5,
                spaceBetween: 5,
              },
            }}
            navigation={true}
            modules={[Navigation, Pagination]}
            pagination={{
              dynamicBullets: true,
            }}
            className="mySwiper"
            onSwiper={(swiper) => setSwiperInstance(swiper)}
          >
            {products?.map((product, index) => (
              <SwiperSlide key={index}>
                <Product_Card
                  image={IMG_URL + product?.image1}
                  product_id={product?.id}
                  text={product?.name}
                  newest={product?.newest}
                  subText={product?.short_description}
                  // price={
                  //   product?.variants[0]?.s_price
                  //     ? product?.variants[0]?.s_price
                  //     : product?.m_variants[0]?.s_price
                  // }
                  price={
                    product?.product_multiple_mains?.[0]
                      ?.product_multiple_variants?.[0]?.s_price
                      ? product?.product_multiple_mains?.[0]
                          ?.product_multiple_variants?.[0]?.s_price
                      : product?.product_variants?.[0]?.s_price
                  }
                  variant_id={product?.product_variants?.id}
                  m_variant_id={
                    product?.product_multiple_mains?.product_multiple_variants
                      ?.id
                  }
                  tradediscount={
                    product?.product_variants?.[0]?.trade
                      ? product?.product_variants?.[0]?.trade
                      : product?.product_multiple_mains?.[0]
                          ?.product_multiple_variants[0]?.trade
                  }
                  schemediscount={
                    product?.product_variants?.[0]?.scheme
                      ? product?.product_variants?.[0]?.scheme
                      : product?.product_multiple_mains?.[0]
                          ?.product_multiple_variants[0]?.scheme
                  }
                  rupeesdiscount={
                    product?.product_variants?.[0]?.discount
                      ? product?.product_variants?.[0]?.discount
                      : product?.product_multiple_mains?.[0]
                          ?.product_multiple_variants[0]?.discount
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <SupermarketModal
            show={addCartModal}
            onHide={() => setAddCartModal(false)}
          />
          <div class="silder-btn">
            <div
              className="back-btn"
              onClick={() => handleMainSliderClick("prev")}
            ></div>
            <div
              className="next-btn"
              onClick={() => handleMainSliderClick("next")}
            ></div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SecondVendor;
