import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const orderGet = async (currentPage) => {
  try {
    const res = await getData(`/useraddress/orderget?page=${currentPage}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const returnOrderGet = async () => {
  try {
    const res = await getData(`/useraddress/order-return`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const OrderCancel = async (id, data) => {
  try {
    const res = await postData(`/useraddress/order-cancel/${id}`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const orderReturn = async (data) => {
  try {
    const res = await postData(`/useraddress/order-return`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const singleOrderGet = async (id) => {
  try {
    const res = await getData(`/useraddress/orderget/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const UserGet = async () => {
  try {
    const res = await getData(`/admin/usersingleget`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const UserGetInvestCount = async () => {
  try {
    const res = await getData(`/admin/get-user-invest-price`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const BirthdayGet = async () => {
  try {
    const res = await getData(`/admin/get-birthday`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const UserEdit = async (data) => {
  try {
    const res = await postData(`/admin/userEdit`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const ChangePassword = async (data) => {
  try {
    const res = await postData(`/admin/change-password`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const ChangePasswordWL = async (data) => {
  try {
    const res = await postData(`/admin/change-password-wl`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const CheckExist = async (data) => {
  try {
    const res = await postData(`/admin/check-exist`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const emailOtpGet = async (data) => {
  try {
    const res = await postData(
      `/without-login/verify-otp/email-otp-send`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const emailOtpVerify = async (data) => {
  try {
    const res = await postData(`/without-login/otp/email-verify-otp`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const walletAmount = async (data) => {
  try {
    const res = await postData(`/admin/wallet`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const walletHistory = async () => {
  try {
    const res = await getData(`/admin/user-wallet-history`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const allComplaintQuery = async () => {
  try {
    const res = await getData(`/admin/get-all-complaint-query`);

    return res;
  } catch (error) {
    console.log(error);
  }
};
