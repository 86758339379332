import React, { useState, useEffect, useContext } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Modal } from "react-bootstrap";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { returnOrderGet } from "../../../utils/apis/myAccount/myAccount";

const PurchasedItemReturnModal = (props) => {
  const createdAt = props.data?.createdAt;
  const formattedDate = createdAt
    ? new Date(createdAt).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      })
    : "";

  const { IMG_URL } = useContext(Context);

  const [returnOrders, setReturnOrder] = useState([]);
  const getReturnOrders = async () => {
    const res = await returnOrderGet();
    if (res?.success) {
      setReturnOrder(res?.data);
    }
  };

  useEffect(() => {
    getReturnOrders();
  }, []);

  var stepPercentage = 0;
  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 50;
  } else if (props.currentStep === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }

  return (
    <>
      <div className="Successfull_Modal_sec">
        <Modal
          className="Successfull_Modal Purchased_Items_Modal my-account"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="product-section-div ">
              <div className="row">
                <div className="col-xxl-5 col-lg-5 col-md-7 col-sm-6 col-12">
                  <div className="border-text-right">
                    <p className="date">Ordered Date: {formattedDate}</p>
                    <h6 className="items">
                      Purchased Items Name: {props.data?.product?.name}
                    </h6>
                    <h6 className="price">
                      ₹{" "}
                      {/* {Number(props.data?.price ?? 0) +
                        (Number(props.data?.price ?? 0) *
                          Number(props.data?.tax_percent ?? 0)) /
                          100} */}
                      {props.data?.amount}
                    </h6>
                  </div>
                </div>
                <div className="col-xxl-7 col-lg-7 col-md-5 col-sm-6 col-12">
                  <div>
                    <p className="date">
                      Order ID : {props.data?.invoice_number}
                    </p>
                    <p className="date">
                      Payment Type : {props.data?.payment_mode}
                    </p>
                    <p className="date">
                      Payment Status :{" "}
                      {props.data?.payment_status?.status === 1
                        ? "Paid"
                        : "UnPaid"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="product-div">
                <div className="row">
                  {props.data?.orders_details?.map((detail, detailIndex) => (
                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5">
                      <div className="product">
                        <img
                          className="img-set"
                          src={IMG_URL + detail?.product?.image1}
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pb-md-0 pb-sm-3 pb-4">
                <h6 className="order ">Order Return Tracking</h6>
              </div>
              <div className="stepper-div mb-md-5 mb-sm-5 ">
                <ProgressBar
                  percent={
                    props.data?.return_order?.return_status_id === 1
                      ? 0
                      : props.data?.return_order?.return_status_id === 2
                      ? 25
                      : props.data?.return_order?.return_status_id === 3
                      ? 50
                      : props.data?.return_order?.return_status_id === 4
                      ? 75
                      : props.data?.return_order?.return_status_id === 5
                      ? 100
                      : 0
                  }
                >
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Return Requested</h6>
                          {/* <p className="date">Seller has processesd your<br /> order. Mon 1st Apr</p> */}
                        </div>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Return Approved</h6>
                          {/* <p className="date">Expected date of pick up 6 Apr</p> */}
                        </div>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Product Picked</h6>
                          {/* <p className="date">Date of Pick up 5 Apr</p> */}
                        </div>
                      </div>
                    )}
                  </Step>

                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Refund Process</h6>
                          {/* <p className="date">5 - 6 Working days</p> */}
                        </div>
                      </div>
                    )}
                  </Step>

                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Refund Done</h6>
                          {/* <p className="date">5 - 6 Working days</p> */}
                        </div>
                      </div>
                    )}
                  </Step>
                </ProgressBar>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default PurchasedItemReturnModal;
