import React, { useContext } from "react";
import "./Purchased_Items_Modal.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Modal } from "react-bootstrap";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router-dom";

function Purchased_Items_Modal(props) {
  console.log(props.data, "props.data");

  var stepPercentage = 0;

  const navigate = useNavigate();
  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 50;
  } else if (props.currentStep === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }

  const { IMG_URL, userdata } = useContext(Context);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);
    return `${day}/${month}/${year}`;
  }

  console.log(props?.data?.createdAt, "createdAt");

  const today = new Date();

  // Ensure `createdAt` is a Date object
  const createdAt = new Date(props?.data?.createdAt);

  const differenceInTime = today.getTime() - createdAt.getTime();
  const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

  console.log(differenceInDays, "differenceInDays");

  const getReturnPeriod = (membership) => {
    if (membership === "Platinum") {
      return 14;
    }
    if (membership === "Gold") {
      return 10;
    }
    return 7;
  };

  const returnPeriod = userdata?.membership
    ? getReturnPeriod(userdata.membership)
    : 7;

  console.log(returnPeriod, "returnPeriod");

  console.log(userdata, "userdata");

  return (
    <>
      <div className="Successfull_Modal_sec">
        <Modal
          className="Successfull_Modal Purchased_Items_Modal my-account"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="product-section-div">
              <h5 className="order-summary-title">Order Summary</h5>
              <p className="date">
                Ordered Date: {formatDate(props.data?.createdAt)}
              </p>
              <p className="date">
                Order ID:{" "}
                {props.data?.orders_details?.map((data) => data?.invoice_no)}
              </p>
              <p className="date">Payment Type: {props.data?.payment_mode}</p>
              <p className="date">
                Payment Status:{" "}
                {props.data?.payment_status?.status === 1 ? "Paid" : "UnPaid"}
              </p>

              {/* Loop through order details to display each entry separately */}
              {props.data?.orders_details?.map((detail, index) => {
                // Resolve product name
                const productName =
                  detail?.product_multiple_variant?.varient_sub_name ||
                  detail?.product_variant?.v_name;

                // Resolve product image
                const productImage =
                  detail?.product_multiple_variant
                    ?.product_multi_varient_images?.[0]?.image ||
                  detail?.product_variant?.product_images?.[0]?.image;

                // Calculate order status percentage
                const orderStatusPercentMap = {
                  1: 0,
                  2: 34,
                  3: 68,
                  4: 100,
                };
                const orderStatusPercent =
                  orderStatusPercentMap[detail?.order_status_id] || 0;

                // Determine if order is eligible for return
                const isReturnEligible =
                  differenceInDays <= returnPeriod &&
                  detail?.order_status_id === 4;

                console.log(isReturnEligible, "isReturnEligible");

                return (
                  <>
                    <div key={index} className="order-detail-section">
                      <h6 className="product-title">
                        Item {index + 1}: {productName}
                      </h6>
                      <div className="row align-items-center mb-4">
                        <div className="col-xxl-3 col-lg-4 col-md-5 col-sm-6">
                          {productImage ? (
                            <img
                              className="product-image"
                              src={`${IMG_URL}${productImage}`}
                              alt={`Product ${index + 1}`}
                            />
                          ) : (
                            <p className="no-image-text">No Image Available</p>
                          )}
                        </div>
                        <div className="col-xxl-9 col-lg-8 col-md-7 col-sm-6">
                          <h6 className="product-price">
                            Price: ₹{props.data?.amount}
                          </h6>
                          <h6 className="product-status">
                            Status: {detail?.order_status?.status_name}
                          </h6>
                        </div>
                      </div>

                      {detail?.order_status_id === 4 ||
                      detail?.order_status_id === 5 ||
                      detail?.order_status_id === 6 ? (
                        <div className="stepper-div">
                          <h5 className="text-danger mt-4">
                            {detail?.order_status_id === 4
                              ? "Order Has Been Delivered"
                              : detail?.order_status_id === 4
                              ? "Order Has Been Cancelled"
                              : "Order Has Been Returned"}
                          </h5>
                          {isReturnEligible && (
                            <div className="return-button delivered-return-btn">
                              <Common_Button
                                className="return-btn"
                                text="Return"
                                onClick={() =>
                                  navigate(`/order-return/${detail?.id}`)
                                }
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <h6 className="order">Order Status</h6>
                          <div className="stepper-div">
                            <ProgressBar percent={orderStatusPercent}>
                              {[
                                "Order Placed",
                                "Processing",
                                "Shipped",
                                "Delivered",
                              ].map((step, idx) => (
                                <Step key={idx}>
                                  {({ accomplished }) => (
                                    <div
                                      className={`indexedStep ${
                                        accomplished ? "accomplished" : ""
                                      }`}
                                    >
                                      <div className="range-text-div">
                                        <h6 className="placed">{step}</h6>
                                      </div>
                                    </div>
                                  )}
                                </Step>
                              ))}
                            </ProgressBar>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="divider">
                      <hr />
                    </div>
                  </>
                );
              })}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Purchased_Items_Modal;
