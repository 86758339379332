import React, { useState, useContext } from "react";
import { Form, Table } from "react-bootstrap";

import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useRazorpay from "react-razorpay";

import { useNavigate } from "react-router-dom";

import { Checkout } from "../../../../utils/apis/orderProcess/orderProcess";
import Cart from "../../Cart/Cart";
import { Context } from "../../../../utils/context";
import ScrollToTop from "../../../ScrollTotop/ScrollTotop";
import { useEffect } from "react";

const ThreeOrder = ({ handlePrevious, paymentMethod, addressId }) => {
  const [modalShow, setModalShow] = useState(false);
  const [numberTwo, setNumberTwo] = useState(1);
  const [numbOne, setNumberOne] = useState(1);

  console.log(paymentMethod, "paymentMethod");

  const navigate = useNavigate();

  const hideModalWithDelay = () => {
    setTimeout(() => {
      setModalShow(false);
      navigate("/");
    }, 3000);
  };

  const {
    Select2Data,
    setopen_delivery,
    open_delivery,
    userdata,
    setOutOfStock,
    outOfStock,
    triggerCartUpdate,
    membershipDiscount,
    setMembershipDiscount,
    quick_delivery,
    setquick_delivery,
  } = useContext(Context);

  //Payment Gateway

  const [Razorpay, openRazorpay] = useRazorpay();
  const [razorid, setRazorId] = useState();
  const [amount, setAmount] = useState();
  const [tax, setTotalTax] = useState();
  const [deliveryInstruction, setDeliveryInstruction] = useState();
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);

  console.log(discount, "discount");

  // setOutOfStock("");

  const handleRazorpay = async (storedValue) => {
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(storedValue) * 100,
      currency: "INR",
      name: "Hiclousia",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(res.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const finalFunction = async (razor = null) => {
    const data = {
      address_id: addressId,
      amount: amount,
      discount: discount,
      membershipDiscount: membershipDiscount,
      tax: tax,
      open_delivery: open_delivery,
      quick_delivery: quick_delivery,
      delivery_instruction: deliveryInstruction,
      payment_mode: paymentMethod,
      transaction_id: razorid,
      payment_status:
        paymentMethod === "wallet" || paymentMethod === "online" ? 1 : 0,
    };

    // Add code_id if a coupon is applied
    if (coupon) {
      data.code_id = coupon;
    }

    // Check payment method and handle accordingly
    if (paymentMethod === "wallet") {
      if (userdata.wallet_amount < amount) {
        alert("Wallet amount is low");
        return;
      }

      data.payment_id = 1;
    } else if (razor) {
      data.payment_id = razor;
    }

    // Proceed with the checkout process
    const response = await Checkout(data);

    if (response?.success) {
      setModalShow(true);
      hideModalWithDelay();
      setopen_delivery(0);
      setquick_delivery(0);
      triggerCartUpdate();
    } else {
      await { code: response?.code, message: response?.message };
      setOutOfStock(response?.message);
    }
  };
  useEffect(() => {
    setOutOfStock("");
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="corfirm-order-holder">
        <section className="checkout-inner-steps">
          <div className="container">
            <div className="confirm_order Product_Cart threeorder">
              <Cart
                type={"checkout"}
                amount={amount}
                setAmount={setAmount}
                discount={discount}
                setDiscount={setDiscount}
                membershipDiscount={membershipDiscount}
                setMembershipDiscount={setMembershipDiscount}
                coupon={coupon}
                setCoupon={setCoupon}
                tax={tax}
                setTotalTax={setTotalTax}
                setDeliveryInstruction={setDeliveryInstruction}
              />
            </div>
            <div className="out_of_stock_text"> {outOfStock}</div>

            <div className="main-btn-div ">
              <Common_Button
                onClick={handlePrevious}
                text="Back"
                className=" back-btn"
              />

              {paymentMethod === "wallet" || paymentMethod === "cod" ? (
                <Common_Button
                  onClick={() => finalFunction()}
                  text="Confirm Order"
                  className="save-btn"
                />
              ) : (
                <Common_Button
                  onClick={() => handleRazorpay(amount)}
                  text="Confirm Order"
                  className="save-btn"
                />
              )}
            </div>
          </div>
        </section>

        {/* <div className="note-holter mt-5">
          <p>
            <span>Note:-</span> Delivery Instructions{" "}
          </p>
          <ul style={{ padding: "0px 0px 0px 20px" }}>
            <li className="delintli">
              Preferred Delivery Time: 9:00 AM - 5:00 PM, Monday to Friday
            </li>
            <li className="delintli">
              Special Days: Deliveries are acceptable on weekends if weekday
              delivery is not possible.
            </li>
            <li className="delintli">
              Notes for Courier: Please contact me if there are any delays or
              issues accessing the building.
            </li>
            <li className="delintli">
              Notification: Text or call once the delivery is complete.
            </li>
          </ul>
        </div> */}
      </div>

      <Successfull_Modal
        message=" Your order has been placed successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ThreeOrder;
