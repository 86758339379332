import React, { useState, useEffect, useContext } from "react";
import { Breadcrumb, Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import "./Main_My_Account.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Context } from "../../../utils/context";
import {
  allComplaintQuery,
  orderGet,
} from "../../../utils/apis/myAccount/myAccount";
import Order_Cancle from "../Account/Order_Cancle/Order_Cancle";
import CallSupportModal from "../../Common_modal/callSupportModal/CallSupportModal";
import GetQuery from "../../Common_modal/getQueryModel/getQuery";
import CustomPagination from "../../Common_modal/CustomPagination";

function Main_My_Account(props) {
  const [modalShow, setModalShow] = React.useState(false);

  const [modalGetShow, setModalGetShow] = useState("");

  const navigate = useNavigate();
  const [showOrderCancel, setShowOrderCancel] = useState(false);

  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 50;
  } else if (props.currentStep === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }
  const steps = [
    {
      links: "/order-history",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/history.png",
      goto: "Order History",
    },
    {
      links: "/my-account",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/account.png",
      goto: "My Account ",
    },
    {
      links: "/address",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/account.png",
      goto: "Add Address",
    },
    {
      links: "/ChangePassword",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/password.png",
      goto: " Change Password",
    },
  ];

  const { IMG_URL, userdata } = useContext(Context);

  const [orders, setOrder] = useState([]);
  const getOrders = async () => {
    const res = await orderGet(currentPage);
    if (res?.success) {
      setOrder(res?.data);
    }
  };

  const [complaintId, setComplaintId] = useState([]);

  const getAllComplaintQuery = async () => {
    const res = await allComplaintQuery();
    if (res?.success) {
      setComplaintId(res?.data);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);
    return `${day}/${month}/${year}`;
  }

  const getReturnPeriod = (membership) => {
    if (membership === "Platinum") {
      return 14;
    }
    if (membership === "Gold") {
      return 10;
    }
    return 7;
  };

  const returnPeriod = userdata?.membership
    ? getReturnPeriod(userdata.membership)
    : 7;

  useEffect(() => {
    getOrders();
    getAllComplaintQuery();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getOrders();
  }, [currentPage]);
  return (
    <>
      <section className="my-account my-account-main">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/information"}>Information</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <Link to={""}>My Orders </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="back-text-holder">
            <Link to={"/information"}>
              <p>Back</p>
            </Link>
          </div>
          <div>
            <div className="">
              <h2 className="title">My Orders</h2>
            </div>
            <div>
              {orders?.data?.map((value, orderIndex) => {
                const today = new Date();
                return (
                  <div className="product-section-div" key={orderIndex}>
                    <div className="row">
                      <div className="col-xxl-10 col-lg-9 col-md-8 col-sm-7">
                        <div>
                          <p className="price custom_date">
                            Order Date: {formatDate(value?.createdAt)}
                          </p>
                          <h6 className="price">Order ID: {value?.id}</h6>
                          {/* <h6 className="price">
                            Payment Status:{" "}
                            {value?.payment_status === 1 ? "Paid" : "Unpaid"}
                          </h6> */}
                        </div>
                      </div>
                    </div>

                    {/* Map through order details */}
                    <div className="product-div">
                      <div className="row">
                        {value?.orders_details?.map((data, detailIndex) => {
                          const createdAt = new Date(data?.createdAt);
                          const differenceInTime =
                            today.getTime() - createdAt.getTime();
                          const differenceInDays = Math.round(
                            differenceInTime / (1000 * 3600 * 24)
                          );

                          return (
                            <div
                              className="product-container"
                              key={detailIndex}
                            >
                              <div className="row">
                                <div className="col-xxl-10 col-lg-9 col-md-8 col-sm-7">
                                  <div>
                                    <p className="date">
                                      Product: {data.product?.name}
                                    </p>
                                    <p className="date">
                                      Price: ₹{" "}
                                      {Number(data?.price) +
                                        (data?.tax
                                          ? (Number(data?.price) *
                                              Number(data?.tax)) /
                                            100
                                          : 0)}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-5">
                                  <div>
                                    <p className="date">
                                      Payment Mode:{" "}
                                      {data?.payment_status == 1
                                        ? "Paid"
                                        : "UnPaid"}
                                    </p>

                                    <p className="date">
                                      Status: {data?.order_status?.order_status}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                {data.product?.image1 && (
                                  <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-4">
                                    <div className="product">
                                      <img
                                        className="img-set"
                                        src={`${IMG_URL}${data.product.image1}`}
                                        alt={`Product ${detailIndex + 1}`}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>

                              {data?.order_status_id === 4 &&
                                differenceInDays <= returnPeriod && (
                                  <div className="return-button delivered-return-btn">
                                    <Common_Button
                                      className="return-btn"
                                      text="Return"
                                      onClick={() =>
                                        navigate(`/order-return/${data?.id}`)
                                      }
                                    />
                                  </div>
                                )}

                              {data?.order_status_id === 5 ||
                              data?.order_status_id === 6 ||
                              data?.order_status_id === 4 ? (
                                <>
                                  <div className="black_btn_holder">
                                    {!complaintId.some(
                                      (complaint) =>
                                        complaint?.order_detail_id === data?.id
                                    ) && (
                                      <Common_Button
                                        className="SubmitBtn mt-5"
                                        text="Raise A Query"
                                        onClick={() => setModalShow(data?.id)}
                                      />
                                    )}
                                    <Common_Button
                                      className="SubmitBtn mt-5"
                                      text="Get Query Details"
                                      onClick={() => setModalGetShow(data?.id)}
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="product-buttons">
                                  <Link
                                    to="/order-cancle"
                                    state={{
                                      show: showOrderCancel,
                                      orderId: data?.id,
                                    }}
                                  >
                                    <Common_Button
                                      className="cancel"
                                      text="Cancel"
                                    />
                                  </Link>
                                </div>
                              )}

                              <div className="divider">
                                <hr />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="paginationss">
            <CustomPagination
              currentPage={currentPage}
              totalPages={orders?.totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </section>

      <CallSupportModal
        show={modalShow}
        order_detail_id={modalShow}
        getOrders={getOrders}
        getAllComplaintQuery={getAllComplaintQuery}
        onHide={() => setModalShow(false)}
      />

      <GetQuery
        show={modalGetShow}
        order_id={modalGetShow}
        onHide={() => setModalGetShow(false)}
      />
    </>
  );
}

export default Main_My_Account;
