// import React from "react";
// import "./Membership_card.css";
// import Common_Button from "../Common_Button/Common_Button";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheck, faXmark, faStar } from "@fortawesome/free-solid-svg-icons";
// import Membership_card_modal from "../../Common_modal/Membership_card_modal/Membership_card_modal";

// function Membership_card({ btnText, headingText, amountText, description }) {
//   const [modalShow, setModalShow] = React.useState(false);

//   const benefits = [
//     { icon: faCheck, text: "Early access to deals.", isCheck: true },
//     { icon: faCheck, text: "Unlock exclusive member deals.", isCheck: true },
//     { icon: faXmark, text: "Celebrate your birthday perks!", isCheck: false },
//     { icon: faXmark, text: "Get VIP customer support.", isCheck: false },
//     {
//       icon: faXmark,
//       text: "Unlock extra discounts selectively",
//       isCheck: false,
//     },
//     { icon: faXmark, text: "Early access to deals.", isCheck: false },
//     { icon: faXmark, text: "Unlock top discounts everywhere.", isCheck: false },
//     { icon: faXmark, text: "Always enjoy free shipping.", isCheck: false },
//     { icon: faXmark, text: "Come to exclusive VIP events.", isCheck: false },
//     { icon: faXmark, text: "Flexible return and exchange.", isCheck: false },
//     { icon: faStar, text: "Rs.3000  Minimum amount spend", isMainText: true },
//   ];

//   var HtmlToReactParser = require("html-to-react").Parser;
//   var htmlToReactParser = new HtmlToReactParser();

//   return (
//     <>
//       <section className="Membership_card">
//         <div className="card">
//           <div className="card-holder">
//             <div className="card-header">
//               <div className="heading-holder">
//                 <h3>
//                   {headingText} {amountText}
//                 </h3>
//               </div>
//             </div>
//             <div className="card-body">
//               <div className="list-holder">
//                 <ul>
//                   {/* {benefits.map((benefit, index) => ( */}
//                   <li
//                   // key={index}
//                   // className={benefit.isMainText ? "main-text" : ""}
//                   >
//                     {/* <FontAwesomeIcon
//                       className={
//                         benefit.isCheck
//                           ? "check-icon"
//                           : benefit.isMainText
//                           ? "star-icon"
//                           : "cross-icon"
//                       }
//                       icon={description}
//                     />{" "} */}
//                     {htmlToReactParser.parse(description)}
//                   </li>
//                   {/* ))} */}
//                 </ul>
//               </div>
//             </div>

//             <div className="card-footer">
//               <div className="btn-holder">
//                 <Common_Button
//                   className={"View_More_Btn"}
//                   text={btnText}
//                   onClick={() => setModalShow(true)}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <Membership_card_modal
//         description={description}
//         btnText={btnText}
//         headingText={headingText}
//         amountText={amountText}
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }

// export default Membership_card;

import React, { useContext, useEffect, useState } from "react";
import "./Membership_card.css";
import Common_Button from "../Common_Button/Common_Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faStar } from "@fortawesome/free-solid-svg-icons";
import Membership_card_modal from "../../Common_modal/Membership_card_modal/Membership_card_modal";
import { Context } from "../../../utils/context";
import { AllPlansFeatures } from "../../../utils/apis/master/Master";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function Membership_card({ btnText, headingText, amountText, details }) {
  const { getData, IMG_URL } = useContext(Context);

  const [plans, setPlans] = useState([]);
  const getPlans = async () => {
    const res = await AllPlansFeatures();
    if (res?.success) {
      setPlans(res.data);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const [modalShow, setModalShow] = React.useState(false);

  const benefits = [
    { icon: faCheck, text: "Early access to deals.", isCheck: true },
    { icon: faCheck, text: "Unlock exclusive member deals.", isCheck: true },
    { icon: faXmark, text: "Celebrate your birthday perks!", isCheck: false },
    { icon: faXmark, text: "Get VIP customer support.", isCheck: false },
    {
      icon: faXmark,
      text: "Unlock extra discounts selectively",
      isCheck: false,
    },
    { icon: faXmark, text: "Early access to deals.", isCheck: false },
    { icon: faXmark, text: "Unlock top discounts everywhere.", isCheck: false },
    { icon: faXmark, text: "Always enjoy free shipping.", isCheck: false },
    { icon: faXmark, text: "Come to exclusive VIP events.", isCheck: false },
    { icon: faXmark, text: "Flexible return and exchange.", isCheck: false },
    { icon: faStar, text: "Rs.3000  Minimum amount spend", isMainText: true },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {headingText == "Silver"
        ? "Spend More Than 3000 To Get Silver Membership"
        : headingText == "Gold"
        ? "Spend More Than 6000 To get Gold Membership"
        : "Spend More Than 10000 To Get Platinum Membership"}
      {/* Simple tooltip */}
    </Tooltip>
  );

  console.log(headingText, "headingText");

  return (
    <>
      <section className="Membership_card">
        <div className="card">
          <div className="card-holder">
            <div className="card-header">
              <div className="heading-holder">
                {/* <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                > */}
                  <h3>{headingText}</h3>
                {/* </OverlayTrigger> */}
              </div>
            </div>
            <div className="card-body">
              <div className="list-holder">
                <ul>
                  {plans
                    .filter((benefit) => {
                      // Check if the feature exists for the current benefit
                      return (
                        details?.membership_multi_features?.some(
                          (Value) => Value.features_id === benefit.id
                        ) || benefit.star_status === 1 // Keep star features
                      );
                    })
                    .slice(0, 5) // Limit to the first 5 filtered benefits
                    .map((benefit, index) => {
                      return (
                        <li
                          key={index}
                          className={benefit.isMainText ? "main-text" : ""}
                        >
                          {/* Prioritize star icon, then check icon */}
                          {benefit.star_status === 1 ? (
                            <FontAwesomeIcon
                              className="star-icon"
                              icon="fa-star"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="check-icon"
                              icon="fa-check"
                            />
                          )}
                          {benefit.name}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>

            <div className="card-footer">
              <div className="btn-holder">
                <Common_Button
                  className={"View_More_Btn"}
                  text={btnText}
                  onClick={() => setModalShow(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Membership_card_modal
        headingText={headingText}
        details={details}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Membership_card;
