import React, { useState, useEffect, useContext, useRef } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "../ScrollTotop/ScrollTotop";
import { useNavigate } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import Notification from "../Notification/Notification";
import { InputGroup } from "react-bootstrap";
import { AppSetup, Webheader } from "../../utils/apis/common/Common";
import { Context } from "../../utils/context";
import { Category } from "../../utils/apis/Product/Product";
import Cookies from "js-cookie";
import { logDOM } from "@testing-library/react";
import { ListGroup } from "react-bootstrap";
library.add(fas);

const Header = () => {
  const location = useLocation();

  const [showNotification, setShowNotification] = useState(false);
  const handleCloseNotification = () => setShowNotification(false);
  const handleShowNotification = () => setShowNotification(true);

  // const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(() => {
    return localStorage.getItem("activeItem") || "home";
  });

  useEffect(() => {
    const path = location.pathname.substr(1);
    updateActiveItem(path || "home");
  }, [location]);

  const updateActiveItem = (newActiveItem) => {
    localStorage.setItem("activeItem", newActiveItem);
    setActiveItem(newActiveItem);
  };
  const [activeLink, setActiveLink] = useState("home");
  useEffect(() => {
    getSetup();
    // getHeader();
    getCategory();
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (id) => {
    navigate(`/select-from-category/${id}`);
    // setActiveLink(id);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const navigateToCategory = (id) => {
    navigate(`/select-from-category/${id}`);
  };

  const [searchToggled, setIsToggled] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsToggled(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleClick = () => {
    setIsToggled((prevState) => !prevState); // Toggle the state based on the previous state
  };

  const {
    cartCount,
    signin,
    setSignin,
    wishlistData,
    addCartModal,
    getData,
    updateCartCount,
  } = useContext(Context);

  const [category, setCategory] = useState([]);
  const getCategory = async () => {
    const res = await Category();
    if (res?.success) {
      setCategory(res.data);
    }
  };
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [product_id, setProductId] = useState([]);

  const getProducts = async () => {
    const res = await getData(`/without-login/product?term=${search}`);
    if (res?.success) {
      setProducts(res?.data);
    }
  };

  useEffect(() => {
    getProducts();
  }, [search]);

  const [header, setHeader] = useState([]);

  // const getHeader = async () => {
  //   const res = await Webheader();
  //   if (res?.success) {
  //     setHeader(res?.data);
  //   }
  // };
  const { IMG_URL } = useContext(Context);

  const [setups, setSetup] = useState();
  const getSetup = async () => {
    const res = await AppSetup();
    if (res?.success) {
      setSetup(res?.data);
    }
  };

  const navigate = useNavigate();
  const handleLogout = async () => {
    Cookies.remove("boomer_bucks_security");
    Cookies.remove("user_id");
    Cookies.remove("boomer_bucks_advertise");
    setSignin(false);
    await updateCartCount();
    navigate("/");
  };

  const handleNavigate = async (product_id) => {
    localStorage.setItem("product_id", product_id);
    // navigate('/vendor-page');
    window.location.href = `/vendor-page`;
    setIsToggled(false);
  };

  // useEffect(() => {
  //   handleNavigate()
  // }, [product_id]);

  return (
    <>
      {/* start header section  */}

      <ScrollToTop />
      <section
        className="second-header-sec"
        style={{
          backgroundColor: setups?.header_color,
        }}
      >
        <div className="container-fluid ">
          <Navbar expand="lg">
            <Navbar.Brand as={Link} to="/">
              <img
                className="headlogo"
                src={IMG_URL + setups?.image}
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              {header?.length > 0 ? (
                <Nav className="mx-auto">
                  {header?.map((item, index) =>
                    item?.id === 7 ? (
                      <NavDropdown
                        title="All Categories"
                        id="basic-nav-dropdown"
                        className="me-3"
                        key={index}
                      >
                        {category?.map((value) => (
                          <NavDropdown.Item
                            key={value?.id}
                            onClick={() => navigateToCategory(value?.id)}
                          >
                            {value.name}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    ) : (
                      <Nav.Link
                        key={index}
                        as={Link}
                        to={item.url} // Assuming item.url contains the route path
                        active={activeItem === item.name} // Assuming item.name contains the name of the item
                        onClick={() => handleNavItemClick(item.name)} // Assuming handleNavItemClick accepts the item name as a parameter
                      >
                        {item.name}
                      </Nav.Link>
                    )
                  )}
                </Nav>
              ) : (
                <Nav className="mx-auto">
                  <Nav.Link
                    as={Link}
                    to="/"
                    active={activeItem === "home"}
                    onClick={handleNavItemClick}
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/near-me"
                    active={activeItem === "near-me"}
                    onClick={handleNavItemClick}
                  >
                    Near Me
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/about-us"
                    active={activeItem === "about-us"}
                    onClick={handleNavItemClick}
                  >
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/contact-us"
                    active={activeItem === "contact-us"}
                    onClick={handleNavItemClick}
                  >
                    Contact Us
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/blog"
                    active={activeItem === "blog"}
                    onClick={handleNavItemClick}
                  >
                    Blog
                  </Nav.Link>
                  {/* <Nav.Link
                    as={Link}
                    to="/become-seller"
                    active={activeItem === "become-seller"}
                    onClick={handleNavItemClick}
                  >
                    Become a Seller
                  </Nav.Link> */}
                  <NavDropdown
                    title="All Categories"
                    id="basic-nav-dropdown"
                    className="me-3"
                  >
                    {category?.map((value) => {
                      return (
                        <NavDropdown.Item
                          key={value?.id}
                          onClick={() => navigateToCategory(value?.id)}
                        >
                          {value.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                </Nav>
              )}

              <div className="all-icon mt-lg-0 mt-md-3 mt-sm-3 mt-3 row">
                <div className="col-lg-7 searchbar-holder-main desktop">
                  <InputGroup className="searchbar-holder">
                    <Form.Control
                      type="text"
                      placeholder="Search Product Here "
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </InputGroup>{" "}
                  {search && (
                    <ListGroup>
                      {products.map((product) => (
                        <ListGroup.Item
                          key={product?.value}
                          onClick={() => handleNavigate(product?.value)}
                        >
                          {product?.label}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </div>

                <div className="col-lg-5  d-flex header-icon-holder">
                  {signin ? (
                    <Link to={"/wallet"}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-wallet"
                        className="cart-logo"
                      />
                    </Link>
                  ) : (
                    <Link to={"/login"}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-wallet"
                        className="cart-logo"
                      />
                    </Link>
                  )}

                  <FontAwesomeIcon
                    onClick={handleShowNotification}
                    icon="fa-solid fa-bell"
                    className="cart-logo"
                  />

                  <Link to={"/product-cart"}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-cart-shopping"
                      className="cart-logo"
                      onClick={handleNavItemClick}
                    />
                    <span className="cart-item-count-text position-absolute top start translate-middle bg-danger1 border border-light rounded-circle">
                      {cartCount}
                    </span>
                  </Link>

                  {signin ? (
                    <>
                      {wishlistData?.length > 0 ? (
                        <Link to={"/wishlist"}>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className="cart-logo"
                            onClick={handleNavItemClick}
                          />
                        </Link>
                      ) : (
                        <Link to={"/empty-wislist"}>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className="cart-logo"
                            onClick={handleNavItemClick}
                          />
                        </Link>
                      )}

                      <Link to={"/information"}>
                        <FontAwesomeIcon
                          icon="fa-solid fa-user"
                          className="cart-logo"
                          onClick={handleNavItemClick}
                        />
                      </Link>
                      <Link className="login" onClick={handleLogout}>
                        Logout
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to={"/empty-wislist"}>
                        <FontAwesomeIcon
                          icon={faHeart}
                          className="cart-logo"
                          onClick={handleNavItemClick}
                        />
                      </Link>
                      <Link to={"/login"} className=" login">
                        Login
                      </Link>
                    </>
                  )}
                </div>

                {/* <FontAwesomeIcon
                  icon="fa-solid fa-magnifying-glass"
                  className="cart-logo"
                  onClick={toggleClick}
                /> */}

                {searchToggled && (
                  <div ref={searchRef} className="input-group-box mb-3">
                    <Form.Control type="text" placeholder="Search here" />
                  </div>
                )}
              </div>
            </Navbar.Collapse>
          </Navbar>

          <div className="mobsearchhed">
            <div className="searchinpuuhead-mob">
              {/* <Form.Control type="search" placeholder="Search here..." /> */}
              {/* <InputGroup className="searchbar-holder"> */}
              <Form.Control
                type="text"
                placeholder="Search here"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              {/* </InputGroup>{" "} */}
              {search && (
                <ListGroup>
                  {products.map((product) => (
                    <ListGroup.Item
                      key={product?.value}
                      onClick={() => handleNavigate(product?.value)}
                    >
                      {product?.label}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
              {/* <FontAwesomeIcon
                icon="fa-solid fa-magnifying-glass"
                className="btnserch"
                // onClick={() => setShow2(!show2)}
              /> */}
            </div>
          </div>

          {/* {show2 &&
            <div>
              <img className="ginanimationimg slide-in-elliptic-top-fwd" src={process.env.PUBLIC_URL + '/assets/Image/Animation/gin.png'} />
            </div>
          } */}
        </div>
      </section>

      <Notification
        show={showNotification}
        handleClose={handleCloseNotification}
      />
      {/* end header section  */}
    </>
  );
};

export default Header;
