// import React, { useEffect } from "react";
// import "./wallet.css";
// import { Link } from "react-router-dom";
// import CoupenCard from "./coupen-card/CoupenCard";
// const Wallet = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0); // Scroll to the top when component mounts
//   }, []);
//   return (
//     <>
//       <section className="wallet desk-top-view-wallet ">
//         <div className="container">
//           <div className="row">
//             <div className="col-6">
//               <nav aria-label="breadcrumb">
//                 <ol className="breadcrumb my-3">
//                   <li className="breadcrumb-item">
//                     <Link to="/">Home</Link>
//                   </li>
//                   <li className="breadcrumb-item active">Wallet</li>
//                 </ol>
//               </nav>
//             </div>
//             <div className="col-6">
//               <Link to="/wallet-transaction">
//                 <div className=" text-end my-3 ">
//                   <button className="btn btn-history">wallet History</button>
//                 </div>
//               </Link>
//             </div>
//           </div>

//           <div className="row me-0 ms-0 justify-content-center">
//             <div className="start-wallet-coupen">
//               <div className="col-lg-4 col-md-6 mx-auto">
//                 <div className="input-group mb-5">
//                   <input
//                     type="text"
//                     className="form-control"
//                     placeholder="Enter amount"
//                     aria-label="Recipient's username"
//                     aria-describedby="basic-addon2"
//                   />
//                   <Link to="/wallet-payment">
//                     {" "}
//                     <span className="input-group-text" id="basic-addon2">
//                       Proceed
//                     </span>
//                   </Link>
//                 </div>
//               </div>
//               <div className="apply-coupen text-center">
//                 <h4>Apply Coupon</h4>
//                 <p>
//                   Take advantage of the coupon and receive a discount enter
//                   coupon code below
//                 </p>
//               </div>
//               <div className="col-lg-4 col-md-6 mx-auto">
//                 <div className="input-group my-3">
//                   <input
//                     type="text"
//                     className="form-control"
//                     placeholder="Enter coupon code"
//                     aria-label="Recipient's username"
//                     aria-describedby="basic-addon2"
//                   />
//                   <span className="input-group-text" id="basic-addon2">
//                     Apply
//                   </span>
//                 </div>
//               </div>
//               <div className="apply-coupen text-center mb-5">
//                 <h4>or choose from below</h4>
//               </div>
//             </div>
//             <div className="wallet-coupon-card-holder">
//               <div className="row">
//                 <div className="col-lg-4 col-md-6 mb-3">
//                   <CoupenCard />
//                 </div>
//                 <div className="col-lg-4 col-md-6 mb-3">
//                   <CoupenCard />
//                 </div>
//                 <div className="col-lg-4 col-md-6 mb-3">
//                   <CoupenCard />
//                 </div>
//                 <div className="col-lg-4 col-md-6 mb-3">
//                   <CoupenCard />
//                 </div>
//                 <div className="col-lg-4 col-md-6 mb-3">
//                   <CoupenCard />
//                 </div>
//                 <div className="col-lg-4 col-md-6 mb-3">
//                   <CoupenCard />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* <section className='wallet mobile-top-view-wallet'>
//                 <div className='container'>
//                     <div className='row'>
//                         <div className='col-6'>
//                             <nav aria-label="breadcrumb">
//                                 <ol className="breadcrumb my-3">
//                                     <li className="breadcrumb-item"><Link to='/'>Home </Link></li>
//                                     <li className="breadcrumb-item active">Wallet</li>
//                                 </ol>
//                             </nav>
//                         </div>
//                         <div className='col-6'>
//                             <Link to='/wallet-transaction'>
//                                 <div className=' text-end my-3 '>
//                                     <button className='btn btn-history'>wallet History</button>
//                                 </div>
//                             </Link>
//                         </div>
//                     </div>
//                     <div className='row'>
//                         <div className='start-wallet-coupen'>
//                             <div className='col-lg-4 col-md-6 mx-auto'>
//                                 <div className="input-group mb-4">
//                                     <input type="text" className="form-control" placeholder="Enter amount" aria-label="Recipient's username" aria-describedby="basic-addon2" />
//                                     <Link to='/wallet-payment'>  <span className="input-group-text ps-3" id="basic-addon2">Proceed</span></Link>
//                                 </div>
//                             </div>
//                             <div className='apply-coupen text-center'>
//                                 <h4>Apply Coupon</h4>
//                                 <p>Take advantage of the coupon and receive a discount enter coupon code below</p>
//                             </div>
//                             <div className='col-lg-4 col-md-6 mx-auto'>
//                                 <div className="input-group mt-3">
//                                     <input type="text" className="form-control" placeholder="Enter coupon code" aria-label="Recipient's username" aria-describedby="basic-addon2" />
//                                     <span className="input-group-text ps-4" id="basic-addon2">Apply</span>
//                                 </div>
//                             </div>
//                             <div className='apply-coupen text-center mt-1 mb-5'>
//                                 <h4>or choose from below</h4>
//                             </div>
//                         </div>

//                         <div className="col-sm-6 col-6  mb-3">
//                             <CoupenCard />
//                         </div>
//                         <div className="col-sm-6 col-6 mb-3">
//                             <CoupenCard />
//                         </div>
//                         <div className="col-sm-6 col-6  mb-3">
//                             <CoupenCard />
//                         </div>
//                         <div className="col-sm-6 col-6 mb-3">
//                             <CoupenCard />
//                         </div>
//                         <div className="col-sm-6 col-6 mb-3">
//                             <CoupenCard />
//                         </div>
//                         <div className="col-sm-6 col-6 mb-3">
//                             <CoupenCard />
//                         </div>

//                     </div>

//                 </div>
//             </section> */}
//     </>
//   );
// };

// export default Wallet;

import React, { useState, useCallback, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { walletAmount } from "../../utils/apis/myAccount/myAccount";
import useRazorpay from "react-razorpay";
import { Context } from "../../utils/context";

const Wallet = () => {
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [Razorpay, isLoaded] = useRazorpay();
  const [razorid, setRazorId] = useState();
  const { postData, signin, userdata, getuserData } = useContext(Context);

  const handleRazorpay = useCallback(async () => {
    if (!amount || amount <= 0) {
      setError("Amount is required and must be greater than 0");
      return; // Prevent proceeding if the validation fails
    }

    setError(""); // Clear error message if validation passes

    try {
      const options = {
        key: "rzp_test_KCP2DQFiHRB3cs",
        amount: amount * 100,
        currency: "INR",
        name: "BOOMERBUCKS",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        handler: async (res) => {
          await submitPayment(res.razorpay_payment_id);
        },
        prefill: {
          name: "Piyush Garg",
          email: "youremail@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  }, [Razorpay, amount]);

  const submitPayment = async (razorpay_id) => {
    try {
      const paydata = {
        type: "credit",
        razorpay_id: razorpay_id,
        user_id: Cookies.get("user_id"),
        amount: amount,
      };

      const response = await walletAmount(paydata);

      if (response?.success) {
        getuserData();
        navigate("/wallet-transaction");
      } else {
        console.log("Payment failed:", response?.message);
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  };

  const handleAmountChange = (e) => {
    const newValue = parseInt(e.target.value, 10); // Convert to integer
    setAmount(isNaN(newValue) ? "" : newValue); // Set the amount or empty if NaN
  };

  return (
    <>
      <section className="wallet desk-top-view-wallet">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-3">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Wallet</li>
                </ol>
              </nav>
            </div>
            <div className="col-6">
              <Link to="/wallet-transaction">
                <div className="text-end my-3">
                  <button className="btn btn-history">Wallet History</button>
                </div>
              </Link>
            </div>
          </div>

          <div className="text-center mb-5">
            <h2>
              <strong>Wallet</strong>
            </h2>
          </div>

          <div className="row me-0 ms-0 justify-content-center">
            <div className="start-wallet-coupen">
              <div className="col-lg-4 col-md-6 mx-auto">
                <div className="input-group mb-5">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter amount"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={amount}
                    onChange={(e) => {
                      // Prevent decimal entry
                      const value = e.target.value;
                      if (!value.includes(".")) {
                        handleAmountChange(e);
                      }
                    }}
                  />
                  <span
                    className="input-group-text"
                    id="basic-addon2"
                    onClick={handleRazorpay}
                  >
                    Proceed
                  </span>
                  {error && <div className="text-danger">{error}</div>}{" "}
                </div>

                {/* Show error message */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wallet;
