import React, { useState, useEffect, useContext } from "react";
import "./History.css";
import { Breadcrumb, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Purchased_Items_Modal from "../../../Common_modal/Purchased_Items_Modal/Purchased_Items_Modal";
import Return_Items_Modal from "../../../Common_modal/Purchased_Items_Modal/PurchasedItemReturnModal";
import { Context } from "../../../../utils/context";
import { orderGet } from "../../../../utils/apis/myAccount/myAccount";
import CustomPagination from "../../../Common_modal/CustomPagination";
function History() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowReturn, setModalShowReturn] = React.useState(false);
  const [modalShowOne, setModalShowTwo] = React.useState(false);

  const { IMG_URL } = useContext(Context);

  const [data, setData] = useState();

  const [orders, setOrder] = useState([]);

  console.log(orders, "orders");

  console.log(orders?.totalPages, "totalPagestotalPagestotalPages");

  const getOrders = async () => {
    const res = await orderGet(currentPage);
    if (res?.success) {
      setOrder(res?.data);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const handleDownload = async (invoice) => {
    try {
      // const response = await getData(`/order/invoice-download/${id}`);

      const filePath = invoice;

      if (!filePath) {
        console.error("File path is undefined or null");
        return;
      }

      const url = `${IMG_URL}/${filePath}`;

      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.rel = "noopener noreferrer";
      a.click();
    } catch (error) {
      console.error("Error opening file:", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getOrders();
  }, [currentPage]);

  return (
    <>
      <section className="my-account  my-account-main checkout-inner-steps ">
        <div className="container">
          <div className="account-table confirm_order Product_Cart">
            <div className="heading_holder">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={"/"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={"/information"}>Information</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <Link to={"/order-history"}>Order history</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="back-text-holder">
              <Link to={"/information"}>
                <p>Back</p>
              </Link>
            </div>

            <div className="product_table">
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Order No.</th>
                    <th>Order Date</th>
                    <th>Delivery Date</th>
                    <th>Total</th>
                    <th>Payment Type</th>
                    <th>Payment Status</th>
                    {/* <th>Order Status</th> */}
                    <th>Order Progress</th>
                    <th>Download Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.data?.map((value, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * 10 + index + 1}</td>
                      <td>{value?.orders_details?.[0]?.invoice_no}</td>
                      <td>{(value?.createdAt).split("T")[0]}</td>
                      <td>{value?.updatedAt?.split("T")[0]}</td>
                      <td>₹ {value.amount}</td>
                      <td>{value?.payment_mode}</td>
                      <td className="green-text">
                        {value?.payment_status?.status == 1 ? "Paid" : "UnPaid"}
                      </td>
                      {/* <td className="green-text">
                        {value?.orders_details?.map(
                          (data) => data?.order_status?.order_status
                        )}
                      </td> */}
                      {value?.order_status?.id === 6 ? (
                        <td
                          className="view-text"
                          onClick={() => {
                            setData(value);
                            setModalShowReturn(true);
                          }}
                        >
                          <button className="viewbtttt"> View</button>
                        </td>
                      ) : (
                        <td
                          className="view-text"
                          onClick={() => {
                            setData(value);
                            setModalShow(true);
                          }}
                        >
                          <button className="viewbtttt"> View</button>
                        </td>
                      )}{" "}
                      <td>
                        <div className="d-flex">
                          <button
                            onClick={() => handleDownload(value?.invoice)}
                            className="btn btn-primary"
                          >
                            Download
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="span-holder"></div>
            </div>

            <div className="paginationss">
              <CustomPagination
                currentPage={currentPage}
                totalPages={orders?.totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </section>

      <Purchased_Items_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
      <Return_Items_Modal
        show={modalShowReturn}
        onHide={() => setModalShowReturn(false)}
        data={data}
      />
    </>
  );
}

export default History;
