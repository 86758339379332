import React, { useEffect, useState } from "react";
import "./getQuery.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import SuccessModalDynamic from "../SuccessModalDynamic/SuccessModalDynamic";
import ErrorModalDynamic from "../ErrorModalDynamic/ErrorModal";
import { ComlaintQuery, GetComlaintQuery } from "../../../utils/apis/Complaint";

const GetQuery = (props) => {
  const [error, setErrors] = useState("");
  const [data, setData] = useState("");

  console.log(data, "data");

  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await GetComlaintQuery(props?.order_id); // Await the response
        if (res?.success) {
          setData(res?.data);
          // reset(setData);
        } else {
          setErrors(res?.message || "Something went wrong!");
          setData("");
          setErrorModal(true); // Show error modal in case of failure
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setErrors("Failed to fetch query data");
        setErrorModal(true);
      }
    };

    if (props?.order_id) {
      fetchData(); // Trigger fetch if order_id is provided
    }
  }, [props?.order_id]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="CallSupportModal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Get Query Status
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {data ? (
            <Form onSubmit={handleSubmit()}>
              <div className="form-group mb-3">
                <Form.Control
                  type="text"
                  placeholder={data?.name}
                  {...register("name", {
                    required: "Name is required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.name,
                  })}
                  readOnly
                />
                <div className="invalid-feedback">{errors?.name?.message}</div>
              </div>
              <div className="form-group mb-3">
                <Form.Control
                  type="email"
                  placeholder={data?.email}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email address",
                    },
                  })}
                  readOnly
                  className={classNames("", {
                    "is-invalid": errors?.email,
                  })}
                />
                <div className="invalid-feedback">{errors?.email?.message}</div>
              </div>
              <div className="form-group mb-3">
                <Form.Control
                  type="text"
                  placeholder={data?.contact_no}
                  inputMode="numeric"
                  maxLength="10"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  {...register("contact_no", {
                    required: "Contact number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Contact number must be 10 digits",
                    },
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.contact_no,
                  })}
                  readOnly
                />
                <div className="invalid-feedback">
                  {errors?.contact_no?.message}
                </div>
              </div>
              <div className="form-group mb-3">
                <FloatingLabel controlId="floatingTextarea2">
                  <Form.Control
                    as="textarea"
                    defaultValue={data?.query}
                    {...register("query", { required: "Query is required" })}
                    className={classNames("", { "is-invalid": errors?.query })}
                    readOnly
                  />
                </FloatingLabel>
                <div className="invalid-feedback">{errors?.query?.message}</div>
              </div>
              <div className="form-group mb-3">
                <Form.Control
                  type="text"
                  placeholder={data?.complaint_ticket_status?.name}
                  {...register("ticket_status", {
                    required: "Status is required",
                  })}
                  readOnly
                  className={classNames("", {
                    "is-invalid": errors?.ticket_status,
                  })}
                />
                <div className="invalid-feedback">
                  {errors?.ticket_status?.message}
                </div>
              </div>

              <div className="form-group mb-3">
                <Form.Control
                  type="text"
                  placeholder={data?.remark}
                  {...register("ticket_status", {
                    required: "Status is required",
                  })}
                  readOnly
                  className={classNames("", {
                    "is-invalid": errors?.remark,
                  })}
                />
                <div className="invalid-feedback">
                  {errors?.remark?.message}
                </div>
              </div>
            </Form>
          ) : (
            <p className="text-center text-muted">No Query found.</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GetQuery;
